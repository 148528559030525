// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/420cc3ba/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/tmp/420cc3ba/src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/tmp/420cc3ba/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mathgames-js": () => import("/tmp/420cc3ba/src/pages/Mathgames.js" /* webpackChunkName: "component---src-pages-mathgames-js" */),
  "component---src-pages-pictures-js": () => import("/tmp/420cc3ba/src/pages/Pictures.js" /* webpackChunkName: "component---src-pages-pictures-js" */)
}

exports.data = () => import("/tmp/420cc3ba/.cache/data.json")

